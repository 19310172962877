:root {
  --m3-sys-color-primary: var(--m3--sys--light--primary);
  --m3-sys-color-on-primary: var(--m3--sys--light--on-primary);
  --m3-sys-color-primary-container: var(--m3--sys--light--primary-container);
  --m3-sys-color-on-primary-container: var(--m3--sys--light--on-primary-container);
  --m3-sys-color-secondary: var(--m3--sys--light--secondary);
  --m3-sys-color-on-secondary: var(--m3--sys--light--on-secondary);
  --m3-sys-color-secondary-container: var(--m3--sys--light--secondary-container);
  --m3-sys-color-on-secondary-container: var(--m3--sys--light--on-secondary-container);
  --m3-sys-color-tertiary: var(--m3--sys--light--tertiary);
  --m3-sys-color-on-tertiary: var(--m3--sys--light--on-tertiary);
  --m3-sys-color-tertiary-container: var(--m3--sys--light--tertiary-container);
  --m3-sys-color-on-tertiary-container: var(--m3--sys--light--on-tertiary-container);
  --m3-sys-color-error: var(--m3--sys--light--error);
  --m3-sys-color-error-container: var(--m3--sys--light--error-container);
  --m3-sys-color-on-error: var(--m3--sys--light--on-error);
  --m3-sys-color-on-error-container: var(--m3--sys--light--on-error-container);
  --m3-sys-color-background: var(--m3--sys--light--background);
  --m3-sys-color-on-background: var(--m3--sys--light--on-background);
  --m3-sys-color-surface: var(--m3--sys--light--surface);
  --m3-sys-color-on-surface: var(--m3--sys--light--on-surface);
  --m3-sys-color-surface-variant: var(--m3--sys--light--surface-variant);
  --m3-sys-color-on-surface-variant: var(--m3--sys--light--on-surface-variant);
  --m3-sys-color-outline: var(--m3--sys--light--outline);
  --m3-sys-color-inverse-on-surface: var(--m3--sys--light--inverse-on-surface);
  --m3-sys-color-inverse-surface: var(--m3--sys--light--inverse-surface);
  --m3-sys-color-inverse-primary: var(--m3--sys--light--inverse-primary);
  --m3-sys-color-shadow: var(--m3--sys--light--shadow);
  --m3-sys-color-surface-tint: var(--m3--sys--light--surface-tint);
  --m3-sys-color-outline-variant: var(--m3--sys--light--outline-variant);
  --m3-sys-color-scrim: var(--m3--sys--light--scrim);
  --m3---elevation--1: var(--m3---elevation--light--1);
  --m3---elevation--2: var(--m3---elevation--light--2);
  --m3---elevation--3: var(--m3---elevation--light--3);
  --m3---elevation--4: var(--m3---elevation--light--4);
  --m3---elevation--5: var(--m3---elevation--light--5);
}
