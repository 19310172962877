.root:global(.MuiButton-root) {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
}

.root:global(.MuiButton-contained),
.root:global(.MuiButton-textPrimary) {
  border-radius: 50px;
}

.root:global(.MuiButton-outlined) {
  border-radius: 8px;
}

.root:global(.MuiButton-textPrimary) {
  color: var(--primary-color);
  background-color: var(--secondary-color);
}

.root.root:global(.MuiButton-outlinedPrimary) {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.root:global(.MuiButton-containedPrimary) {
  background-color: var(--primary-color);
  &:hover {
    background-color: var(--primary-hover-color);
  }
}

.root.root:global(.MuiButton-outlinedSecondary) {
  border-color: #D0D5DD;
  color: var(--font-secondary-color);
  &:hover {
    background-color: rgba(208, 213, 221, 0.04);

  }
}

.root:global(.MuiButton-containedSecondary) {
  background-color: var(--light-blue);
  color: var(--purple-color);
  &:hover {
    background-color: var(--secondary-hover-color);
  }
}
