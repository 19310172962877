.root {
  padding: 0 1rem 1rem 1rem;

  .sentence {
    font-family: 'PingFang HK';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -1.7px;
    color: #43474E;
    margin-bottom: 16px;
    margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .actionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 37px;
    margin-top: 15px;

    .btnCheck {
      border-radius: 26px;
      text-transform: capitalize;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      svg {
        margin-left: 0.3rem;
      }
    }
  }

  .result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 86px;

    .success,
    .fail {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .success {
      color: #00de27;
    }

    .fail {
      color: #f4483c;
    }
  }
}

.default {
  color: #73777F !important;
}

.list_item_success {
  background-color: #B8F397 !important;
  color: #072100 !important;
}

.list_item_icon_success {
  color: #072100 !important;
}

.list_item_fail {
  background-color: #FFDAD5 !important;
  color: #410000 !important;
}

.list_item_icon_fail {
  color: #410000 !important;
}

.checkIcon {
  margin-left: 8px;
}

.eastIcon {
  margin-left: 8px;
}
