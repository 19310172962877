.root {
  &.half {
    width: 130px;
    height: 65px;
    .good,
    .neutral,
    .bad {
      position: relative;
      display: flex;
      justify-content: center;
      .text {
        position: absolute;
        top: calc(50% - 32px / 2 + 22px);
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
      }
      svg path:nth-child(2) {
        transition: stroke-dashoffset 1s;
        animation: load 300ms cubic-bezier(0.4, 0, 1, 1);
      }
      @keyframes load {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
    .good {
      svg path:nth-child(2) {
        stroke: var(--green-600);
      }
      .text {
        color: var(--green-900);
      }
    }
    .neutral {
      svg path:nth-child(2) {
        stroke: var(--gray-700);
      }
      .text {
        color: var(--gray-900);
      }
    }
    .bad {
      svg path:nth-child(2) {
        stroke: var(--error-600);
      }
      .text {
        color: var(--error-900);
      }
    }
  }
  &.circular {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 97px;
    .circleWrap {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 58px;
      :global {
        .MuiCircularProgress-root {
          &:nth-child(1) {
            z-index: 1;
          }
          &:nth-child(2) {
            color: var(--gray-100);
          }
        }
        .MuiCircularProgress-colorPrimary {
          & ~ .MuiCircularProgress-colorPrimary {
            color: var(--violet-200);
          }
          &:nth-child(1) {
            color: var(--violet-600);
            z-index: 1;
          }
        }
        .MuiCircularProgress-colorSecondary {
          & ~ .MuiCircularProgress-colorPrimary {
            color: var(--teal-200);
          }
          &:nth-child(1) {
            color: var(--teal-600);
            z-index: 1;
          }
        }
        .MuiCircularProgress-colorSuccess {
          & ~ .MuiCircularProgress-colorPrimary {
            color: var(--cyan-200);
          }
          &:nth-child(1) {
            color: var(--cyan-600);
            z-index: 1;
          }
        }
      }
      .text {
        position: absolute;
      }
    }
    .label,
    .text {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      &.primary {
        color: var(--violet-700);
      }
      &.secondary {
        color: var(--teal-700);
      }
      &.success {
        color: var(--cyan-700);
      }
    }
  }
  &.linear {
    display: flex;
    flex-direction: row;
    padding: 16px 0;
    gap: 18px;
    .icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      flex: none;
      &.good {
        background-color: var(--green-100);
      }
      &.neutral {
        background-color: var(--gray-100);
      }
      &.bad {
        background-color: var(--error-100);
      }
    }
    .wrap {
      display: flex;
      flex-direction: column;
      padding: 0px;
      gap: 8px;
      width: 100%;
      .text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        &.good {
          color: var(--green-700);
        }
        &.neutral {
          color: var(--gray-700);
        }
        &.bad {
          color: var(--error-700);
        }
      }
      :global {
        .MuiLinearProgress-root {
          height: 8px;
          &,
          .MuiLinearProgress-bar {
            border-radius: 4px;
          }
          &.MuiLinearProgress-colorSuccess {
            background-color: var(--green-100);
            .MuiLinearProgress-barColorSuccess {
              background-color: var(--green-700);
            }
          }
          &.MuiLinearProgress-colorInfo {
            background-color: var(--gray-100);
            .MuiLinearProgress-barColorInfo {
              background-color: var(--gray-700);
            }
          }
          &.MuiLinearProgress-colorError {
            background-color: var(--error-100);
            .MuiLinearProgress-barColorError {
              background-color: var(--error-700);
            }
          }
        }
      }
    }
  }
}
