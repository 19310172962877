.root {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 12px;
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 16px;

  .icon {
    font-size: 20px;
  }

  .text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  &.success {
    background: var(--green-25);
    border: 1px solid var(--green-600);
    .icon {
      &::after {
        content: '\e86c';
        color: var(--green-600);
      }
    }

    .text {
      color: var(--green-600);
    }
  }

  &.error {
    .icon {
      &::after {
        content: '\e000';
        color: var(--error-700);
      }
    }

    .text {
      color: var(--error-700);
    }
  }

  &.neutral {
    background: var(--gray-25);
    border: 1px solid var(--gray-300);

    .icon {
      &::after {
        content: '\e000';
        color: var(--gray-700);
      }
    }

    .text {
      color: var(--gray-700);
    }
  }

  &.info {
    border: 1px solid #3F5AA9;
    background: #FEFBFF;

    .icon {
      &::after {
        content: '\e000';
        color: #3F5AA9;
      }
    }

    .text{
      color: #3F5AA9;
    }
  }

  &.warning {
    background: var(--error-25);
    border: 1px solid var(--error-300);

    .icon {
      &::after {
        content: '\e000';
        color: var(--error-700);
      }
    }

    .text {
      color: var(--error-700);
    }
  }
}
