.dialog {
  position: absolute !important;
  bottom: 0 !important;
  margin:0 !important;
  width: 100%;
  max-height: 75% !important;
  border-radius: 20px 20px 0px 0px !important;
}

.webDialog {
  position: absolute !important;
  bottom: 0 !important;
  margin:0 !important;
  width: 500px;
  max-height: 75% !important;
  border-radius: 20px 20px 0px 0px !important;
}

.title{
  font-weight: 500;
  font-size: 1.3rem !important;
  color: #554d89;
  margin-bottom: 0.5rem;
  text-align: center;
}
