.root {
  position: relative;
  max-width: 260px;
  .pointsContainer {
    position: absolute;
    top: 32%;
    left: 50%;
    transform: translateX(-50%);
    .percentage {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 44px;
      color: var(--font-primary-color);
      text-align: center;
    }
    .points {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 26px;
      text-align: center;
      color: var(--font-secondary-color);
      opacity: 0.6;
    }
  }
}
