.root {
  height: 100%;
  .container {
    height: 100%;
  }
  .form {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
}
