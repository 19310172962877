.root,
.rootWeb {
  position: absolute !important;
  bottom: 0 !important;
  margin: 0 !important;
  width: 100%;
  max-height: 85% !important;
  border-radius: 20px 20px 0px 0px !important;
  .title {
    color: #C11574;
    background: #FDF2FA;
    font-size: 14px;
  }
  .icon {
    color: #C11574 !important;
  }
  :global {
    .MuiDialogContent-root {
      padding: 0;
    }
  }
}

.rootWeb {
  width: 500px;
}
