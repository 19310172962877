:root {
  /* Color styles */
  --m3--white: rgba(255, 255, 255, 1);
  --m3--black: rgba(0, 0, 0, 1);
  /* light */
  --m3--sys--light--primary: rgba(182, 0, 116, 1);
  --m3--sys--light--on-primary: rgba(255, 255, 255, 1);
  --m3--sys--light--primary-container: rgba(255, 216, 230, 1);
  --m3--sys--light--on-primary-container: rgba(61, 0, 36, 1);
  --m3--sys--light--primary-fixed: rgba(255, 216, 230, 1);
  --m3--sys--light--on-primary-fixed: rgba(61, 0, 36, 1);
  --m3--sys--light--primary-fixed-dim: rgba(255, 175, 208, 1);
  --m3--sys--light--on-primary-fixed-variant: rgba(140, 0, 88, 1);
  --m3--sys--light--secondary: rgba(73, 88, 169, 1);
  --m3--sys--light--on-secondary: rgba(255, 255, 255, 1);
  --m3--sys--light--secondary-container: rgba(222, 224, 255, 1);
  --m3--sys--light--on-secondary-container: rgba(0, 16, 90, 1);
  --m3--sys--light--secondary-fixed: rgba(222, 224, 255, 1);
  --m3--sys--light--on-secondary-fixed: rgba(0, 16, 90, 1);
  --m3--sys--light--secondary-fixed-dim: rgba(186, 195, 255, 1);
  --m3--sys--light--on-secondary-fixed-variant: rgba(48, 63, 144, 1);
  --m3--sys--light--tertiary: rgba(0, 99, 154, 1);
  --m3--sys--light--on-tertiary: rgba(255, 255, 255, 1);
  --m3--sys--light--tertiary-container: rgba(206, 229, 255, 1);
  --m3--sys--light--on-tertiary-container: rgba(0, 29, 50, 1);
  --m3--sys--light--tertiary-fixed: rgba(206, 229, 255, 1);
  --m3--sys--light--on-tertiary-fixed: rgba(0, 29, 50, 1);
  --m3--sys--light--tertiary-fixed-dim: rgba(150, 204, 255, 1);
  --m3--sys--light--on-tertiary-fixed-variant: rgba(0, 74, 118, 1);
  --m3--sys--light--error: rgba(182, 36, 25, 1);
  --m3--sys--light--on-error: rgba(255, 255, 255, 1);
  --m3--sys--light--error-container: rgba(255, 218, 213, 1);
  --m3--sys--light--on-error-container: rgba(65, 0, 0, 1);
  --m3--sys--light--outline: rgba(115, 119, 127, 1);
  --m3--sys--light--background: rgba(251, 252, 253, 1);
  --m3--sys--light--on-background: rgba(25, 28, 29, 1);
  --m3--sys--light--surface: rgba(248, 250, 250, 1);
  --m3--sys--light--on-surface: rgba(25, 28, 29, 1);
  --m3--sys--light--surface-variant: rgba(223, 226, 235, 1);
  --m3--sys--light--on-surface-variant: rgba(67, 71, 78, 1);
  --m3--sys--light--inverse-surface: rgba(46, 49, 50, 1);
  --m3--sys--light--inverse-on-surface: rgba(239, 241, 242, 1);
  --m3--sys--light--inverse-primary: rgba(255, 175, 208, 1);
  --m3--sys--light--shadow: rgba(0, 0, 0, 1);
  --m3--sys--light--surface-tint: rgba(182, 0, 116, 1);
  --m3--sys--light--outline-variant: rgba(195, 198, 207, 1);
  --m3--sys--light--scrim: rgba(0, 0, 0, 1);
  --m3--sys--light--surface-container-highest: rgba(225, 227, 228, 1);
  --m3--sys--light--surface-container-high: rgba(230, 232, 233, 1);
  --m3--sys--light--surface-container: rgba(236, 238, 239, 1);
  --m3--sys--light--surface-container-low: rgba(242, 244, 245, 1);
  --m3--sys--light--surface-container-lowest: rgba(255, 255, 255, 1);
  --m3--sys--light--surface-bright: rgba(248, 250, 250, 1);
  --m3--sys--light--surface-dim: rgba(216, 218, 219, 1);
  /* Dark */
  --m3--sys--dark--primary: rgba(255, 175, 208, 1);
  --m3--sys--dark--on-primary: rgba(99, 0, 61, 1);
  --m3--sys--dark--primary-container: rgba(140, 0, 88, 1);
  --m3--sys--dark--on-primary-container: rgba(255, 216, 230, 1);
  --m3--sys--dark--primary-fixed: rgba(255, 216, 230, 1);
  --m3--sys--dark--on-primary-fixed: rgba(61, 0, 36, 1);
  --m3--sys--dark--primary-fixed-dim: rgba(255, 175, 208, 1);
  --m3--sys--dark--on-primary-fixed-variant: rgba(140, 0, 88, 1);
  --m3--sys--dark--secondary: rgba(186, 195, 255, 1);
  --m3--sys--dark--on-secondary: rgba(22, 39, 120, 1);
  --m3--sys--dark--secondary-container: rgba(48, 63, 144, 1);
  --m3--sys--dark--on-secondary-container: rgba(222, 224, 255, 1);
  --m3--sys--dark--secondary-fixed: rgba(222, 224, 255, 1);
  --m3--sys--dark--on-secondary-fixed: rgba(0, 16, 90, 1);
  --m3--sys--dark--secondary-fixed-dim: rgba(186, 195, 255, 1);
  --m3--sys--dark--on-secondary-fixed-variant: rgba(48, 63, 144, 1);
  --m3--sys--dark--tertiary: rgba(150, 204, 255, 1);
  --m3--sys--dark--on-tertiary: rgba(0, 51, 83, 1);
  --m3--sys--dark--tertiary-container: rgba(0, 74, 118, 1);
  --m3--sys--dark--on-tertiary-container: rgba(206, 229, 255, 1);
  --m3--sys--dark--tertiary-fixed: rgba(206, 229, 255, 1);
  --m3--sys--dark--on-tertiary-fixed: rgba(0, 29, 50, 1);
  --m3--sys--dark--tertiary-fixed-dim: rgba(150, 204, 255, 1);
  --m3--sys--dark--on-tertiary-fixed-variant: rgba(0, 74, 118, 1);
  --m3--sys--dark--error: rgba(255, 180, 168, 1);
  --m3--sys--dark--on-error: rgba(105, 0, 1, 1);
  --m3--sys--dark--error-container: rgba(147, 3, 3, 1);
  --m3--sys--dark--on-error-container: rgba(255, 218, 213, 1);
  --m3--sys--dark--outline: rgba(141, 145, 153, 1);
  --m3--sys--dark--background: rgba(25, 28, 29, 1);
  --m3--sys--dark--on-background: rgba(225, 227, 228, 1);
  --m3--sys--dark--surface: rgba(17, 20, 21, 1);
  --m3--sys--dark--on-surface: rgba(196, 199, 200, 1);
  --m3--sys--dark--surface-variant: rgba(67, 71, 78, 1);
  --m3--sys--dark--on-surface-variant: rgba(195, 198, 207, 1);
  --m3--sys--dark--inverse-surface: rgba(225, 227, 228, 1);
  --m3--sys--dark--inverse-on-surface: rgba(25, 28, 29, 1);
  --m3--sys--dark--inverse-primary: rgba(182, 0, 116, 1);
  --m3--sys--dark--shadow: rgba(0, 0, 0, 1);
  --m3--sys--dark--surface-tint: rgba(255, 175, 208, 1);
  --m3--sys--dark--outline-variant: rgba(67, 71, 78, 1);
  --m3--sys--dark--scrim: rgba(0, 0, 0, 1);
  --m3--sys--dark--surface-container-highest: rgba(50, 53, 54, 1);
  --m3--sys--dark--surface-container-high: rgba(39, 42, 43, 1);
  --m3--sys--dark--surface-container: rgba(29, 32, 33, 1);
  --m3--sys--dark--surface-container-low: rgba(25, 28, 29, 1);
  --m3--sys--dark--surface-container-lowest: rgba(11, 15, 16, 1);
  --m3--sys--dark--surface-bright: rgba(54, 58, 59, 1);
  --m3--sys--dark--surface-dim: rgba(17, 20, 21, 1);
  /* primary */
  --m3--ref--primary--primary0: rgba(0, 0, 0, 1);
  --m3--ref--primary--primary10: rgba(61, 0, 36, 1);
  --m3--ref--primary--primary20: rgba(99, 0, 61, 1);
  --m3--ref--primary--primary30: rgba(140, 0, 88, 1);
  --m3--ref--primary--primary40: rgba(182, 0, 116, 1);
  --m3--ref--primary--primary50: rgba(216, 46, 142, 1);
  --m3--ref--primary--primary60: rgba(249, 75, 169, 1);
  --m3--ref--primary--primary70: rgba(255, 129, 189, 1);
  --m3--ref--primary--primary80: rgba(255, 175, 208, 1);
  --m3--ref--primary--primary90: rgba(255, 216, 230, 1);
  --m3--ref--primary--primary95: rgba(255, 236, 241, 1);
  --m3--ref--primary--primary99: rgba(255, 251, 255, 1);
  --m3--ref--primary--primary100: rgba(255, 255, 255, 1);
  --m3--ref--primary--primary4: rgba(37, 0, 20, 1);
  --m3--ref--primary--primary5: rgba(42, 0, 23, 1);
  --m3--ref--primary--primary6: rgba(46, 0, 26, 1);
  --m3--ref--primary--primary12: rgba(69, 0, 41, 1);
  --m3--ref--primary--primary17: rgba(87, 0, 53, 1);
  --m3--ref--primary--primary22: rgba(107, 0, 66, 1);
  --m3--ref--primary--primary24: rgba(115, 0, 71, 1);
  --m3--ref--primary--primary25: rgba(119, 0, 74, 1);
  --m3--ref--primary--primary35: rgba(161, 0, 102, 1);
  --m3--ref--primary--primary87: rgba(255, 204, 223, 1);
  --m3--ref--primary--primary92: rgba(255, 224, 234, 1);
  --m3--ref--primary--primary94: rgba(255, 232, 239, 1);
  --m3--ref--primary--primary96: rgba(255, 240, 243, 1);
  --m3--ref--primary--primary98: rgba(255, 248, 248, 1);
  /* secondary */
  --m3--ref--secondary--secondary0: rgba(0, 0, 0, 1);
  --m3--ref--secondary--secondary10: rgba(0, 16, 90, 1);
  --m3--ref--secondary--secondary20: rgba(22, 39, 120, 1);
  --m3--ref--secondary--secondary30: rgba(48, 63, 144, 1);
  --m3--ref--secondary--secondary40: rgba(73, 88, 169, 1);
  --m3--ref--secondary--secondary50: rgba(98, 113, 196, 1);
  --m3--ref--secondary--secondary60: rgba(124, 139, 224, 1);
  --m3--ref--secondary--secondary70: rgba(151, 166, 253, 1);
  --m3--ref--secondary--secondary80: rgba(186, 195, 255, 1);
  --m3--ref--secondary--secondary90: rgba(222, 224, 255, 1);
  --m3--ref--secondary--secondary95: rgba(240, 239, 255, 1);
  --m3--ref--secondary--secondary99: rgba(254, 251, 255, 1);
  --m3--ref--secondary--secondary100: rgba(255, 255, 255, 1);
  --m3--ref--secondary--secondary4: rgba(0, 7, 57, 1);
  --m3--ref--secondary--secondary5: rgba(0, 9, 63, 1);
  --m3--ref--secondary--secondary6: rgba(0, 10, 70, 1);
  --m3--ref--secondary--secondary12: rgba(0, 19, 100, 1);
  --m3--ref--secondary--secondary17: rgba(13, 31, 114, 1);
  --m3--ref--secondary--secondary22: rgba(27, 44, 125, 1);
  --m3--ref--secondary--secondary24: rgba(33, 49, 129, 1);
  --m3--ref--secondary--secondary25: rgba(35, 51, 132, 1);
  --m3--ref--secondary--secondary35: rgba(60, 76, 156, 1);
  --m3--ref--secondary--secondary87: rgba(211, 216, 255, 1);
  --m3--ref--secondary--secondary92: rgba(229, 230, 255, 1);
  --m3--ref--secondary--secondary94: rgba(236, 236, 255, 1);
  --m3--ref--secondary--secondary96: rgba(244, 242, 255, 1);
  --m3--ref--secondary--secondary98: rgba(251, 248, 255, 1);
  /* tertiary */
  --m3--ref--tertiary--tertiary0: rgba(0, 0, 0, 1);
  --m3--ref--tertiary--tertiary10: rgba(0, 29, 50, 1);
  --m3--ref--tertiary--tertiary20: rgba(0, 51, 83, 1);
  --m3--ref--tertiary--tertiary30: rgba(0, 74, 118, 1);
  --m3--ref--tertiary--tertiary40: rgba(0, 99, 154, 1);
  --m3--ref--tertiary--tertiary50: rgba(0, 125, 193, 1);
  --m3--ref--tertiary--tertiary60: rgba(7, 151, 232, 1);
  --m3--ref--tertiary--tertiary70: rgba(79, 178, 255, 1);
  --m3--ref--tertiary--tertiary80: rgba(150, 204, 255, 1);
  --m3--ref--tertiary--tertiary90: rgba(206, 229, 255, 1);
  --m3--ref--tertiary--tertiary95: rgba(232, 242, 255, 1);
  --m3--ref--tertiary--tertiary99: rgba(252, 252, 255, 1);
  --m3--ref--tertiary--tertiary100: rgba(255, 255, 255, 1);
  --m3--ref--tertiary--tertiary4: rgba(0, 15, 30, 1);
  --m3--ref--tertiary--tertiary5: rgba(0, 18, 34, 1);
  --m3--ref--tertiary--tertiary6: rgba(0, 21, 38, 1);
  --m3--ref--tertiary--tertiary12: rgba(0, 33, 57, 1);
  --m3--ref--tertiary--tertiary17: rgba(0, 44, 73, 1);
  --m3--ref--tertiary--tertiary22: rgba(0, 56, 90, 1);
  --m3--ref--tertiary--tertiary24: rgba(0, 60, 97, 1);
  --m3--ref--tertiary--tertiary25: rgba(0, 62, 100, 1);
  --m3--ref--tertiary--tertiary35: rgba(0, 86, 136, 1);
  --m3--ref--tertiary--tertiary87: rgba(190, 221, 255, 1);
  --m3--ref--tertiary--tertiary92: rgba(216, 234, 255, 1);
  --m3--ref--tertiary--tertiary94: rgba(227, 239, 255, 1);
  --m3--ref--tertiary--tertiary96: rgba(237, 244, 255, 1);
  --m3--ref--tertiary--tertiary98: rgba(247, 249, 255, 1);
  /* error */
  --m3--ref--error--error0: rgba(0, 0, 0, 1);
  --m3--ref--error--error10: rgba(65, 0, 0, 1);
  --m3--ref--error--error20: rgba(105, 0, 1, 1);
  --m3--ref--error--error30: rgba(147, 3, 3, 1);
  --m3--ref--error--error40: rgba(182, 36, 25, 1);
  --m3--ref--error--error50: rgba(217, 62, 47, 1);
  --m3--ref--error--error60: rgba(253, 88, 69, 1);
  --m3--ref--error--error70: rgba(255, 138, 121, 1);
  --m3--ref--error--error80: rgba(255, 180, 168, 1);
  --m3--ref--error--error90: rgba(255, 218, 213, 1);
  --m3--ref--error--error95: rgba(255, 237, 234, 1);
  --m3--ref--error--error99: rgba(255, 251, 255, 1);
  --m3--ref--error--error100: rgba(255, 255, 255, 1);
  --m3--ref--error--error4: rgba(40, 0, 0, 1);
  --m3--ref--error--error5: rgba(45, 0, 0, 1);
  --m3--ref--error--error6: rgba(49, 0, 0, 1);
  --m3--ref--error--error12: rgba(73, 0, 0, 1);
  --m3--ref--error--error17: rgba(93, 0, 0, 1);
  --m3--ref--error--error22: rgba(113, 0, 1, 1);
  --m3--ref--error--error24: rgba(121, 0, 1, 1);
  --m3--ref--error--error25: rgba(126, 0, 1, 1);
  --m3--ref--error--error35: rgba(164, 22, 14, 1);
  --m3--ref--error--error87: rgba(255, 207, 200, 1);
  --m3--ref--error--error92: rgba(255, 226, 221, 1);
  --m3--ref--error--error94: rgba(255, 233, 230, 1);
  --m3--ref--error--error96: rgba(255, 240, 238, 1);
  --m3--ref--error--error98: rgba(255, 248, 246, 1);
  /* neutral */
  --m3--ref--neutral--neutral0: rgba(0, 0, 0, 1);
  --m3--ref--neutral--neutral4: rgba(11, 15, 16, 1);
  --m3--ref--neutral--neutral6: rgba(17, 20, 21, 1);
  --m3--ref--neutral--neutral10: rgba(25, 28, 29, 1);
  --m3--ref--neutral--neutral12: rgba(29, 32, 33, 1);
  --m3--ref--neutral--neutral17: rgba(39, 42, 43, 1);
  --m3--ref--neutral--neutral20: rgba(46, 49, 50, 1);
  --m3--ref--neutral--neutral22: rgba(50, 53, 54, 1);
  --m3--ref--neutral--neutral24: rgba(54, 58, 59, 1);
  --m3--ref--neutral--neutral30: rgba(68, 71, 72, 1);
  --m3--ref--neutral--neutral40: rgba(92, 95, 96, 1);
  --m3--ref--neutral--neutral50: rgba(117, 119, 120, 1);
  --m3--ref--neutral--neutral60: rgba(142, 145, 146, 1);
  --m3--ref--neutral--neutral70: rgba(169, 172, 173, 1);
  --m3--ref--neutral--neutral80: rgba(196, 199, 200, 1);
  --m3--ref--neutral--neutral87: rgba(216, 218, 219, 1);
  --m3--ref--neutral--neutral90: rgba(225, 227, 228, 1);
  --m3--ref--neutral--neutral92: rgba(230, 232, 233, 1);
  --m3--ref--neutral--neutral94: rgba(236, 238, 239, 1);
  --m3--ref--neutral--neutral95: rgba(239, 241, 242, 1);
  --m3--ref--neutral--neutral96: rgba(242, 244, 245, 1);
  --m3--ref--neutral--neutral99: rgba(251, 252, 253, 1);
  --m3--ref--neutral--neutral100: rgba(255, 255, 255, 1);
  --m3--ref--neutral--neutral5: rgba(14, 17, 18, 1);
  --m3--ref--neutral--neutral25: rgba(57, 60, 61, 1);
  --m3--ref--neutral--neutral35: rgba(80, 83, 84, 1);
  --m3--ref--neutral--neutral98: rgba(248, 250, 250, 1);
  /* neutral-variant */
  --m3--ref--neutral-variant--neutral-variant0: rgba(0, 0, 0, 1);
  --m3--ref--neutral-variant--neutral-variant10: rgba(23, 28, 34, 1);
  --m3--ref--neutral-variant--neutral-variant20: rgba(44, 49, 55, 1);
  --m3--ref--neutral-variant--neutral-variant30: rgba(67, 71, 78, 1);
  --m3--ref--neutral-variant--neutral-variant40: rgba(90, 95, 102, 1);
  --m3--ref--neutral-variant--neutral-variant50: rgba(115, 119, 127, 1);
  --m3--ref--neutral-variant--neutral-variant60: rgba(141, 145, 153, 1);
  --m3--ref--neutral-variant--neutral-variant70: rgba(167, 171, 180, 1);
  --m3--ref--neutral-variant--neutral-variant80: rgba(195, 198, 207, 1);
  --m3--ref--neutral-variant--neutral-variant90: rgba(223, 226, 235, 1);
  --m3--ref--neutral-variant--neutral-variant95: rgba(237, 241, 250, 1);
  --m3--ref--neutral-variant--neutral-variant99: rgba(253, 252, 255, 1);
  --m3--ref--neutral-variant--neutral-variant100: rgba(255, 255, 255, 1);
  --m3--ref--neutral-variant--neutral-variant4: rgba(10, 15, 20, 1);
  --m3--ref--neutral-variant--neutral-variant5: rgba(13, 17, 23, 1);
  --m3--ref--neutral-variant--neutral-variant6: rgba(15, 20, 26, 1);
  --m3--ref--neutral-variant--neutral-variant12: rgba(27, 32, 38, 1);
  --m3--ref--neutral-variant--neutral-variant17: rgba(38, 42, 49, 1);
  --m3--ref--neutral-variant--neutral-variant22: rgba(49, 53, 60, 1);
  --m3--ref--neutral-variant--neutral-variant24: rgba(53, 57, 64, 1);
  --m3--ref--neutral-variant--neutral-variant25: rgba(55, 60, 67, 1);
  --m3--ref--neutral-variant--neutral-variant35: rgba(78, 83, 90, 1);
  --m3--ref--neutral-variant--neutral-variant87: rgba(214, 218, 227, 1);
  --m3--ref--neutral-variant--neutral-variant92: rgba(229, 232, 241, 1);
  --m3--ref--neutral-variant--neutral-variant94: rgba(234, 238, 247, 1);
  --m3--ref--neutral-variant--neutral-variant96: rgba(240, 244, 252, 1);
  --m3--ref--neutral-variant--neutral-variant98: rgba(248, 249, 255, 1);

  --m3--key-colors--primary: rgba(182, 0, 116, 1);
  --m3--key-colors--secondary: rgba(73, 88, 169, 1);
  --m3--key-colors--tertiary: rgba(5, 151, 232, 1);
  --m3--key-colors--error: rgba(180, 35, 24, 1);
  --m3--key-colors--neutral: rgba(250, 252, 253, 1);
  --m3--key-colors--neutral-variant: rgba(105, 117, 134, 1);
  --m3--source--seed: rgba(182, 0, 116, 1);
  --m3--surfaces--light--surface1: rgba(248, 250, 250, 1);
  --m3--surfaces--light--surface2: rgba(248, 250, 250, 1);
  --m3--surfaces--light--surface3: rgba(248, 250, 250, 1);
  --m3--surfaces--light--surface4: rgba(248, 250, 250, 1);
  --m3--surfaces--light--surface5: rgba(248, 250, 250, 1);
  --m3--surfaces--dark--surface1: rgba(17, 20, 21, 1);
  --m3--surfaces--dark--surface2: rgba(17, 20, 21, 1);
  --m3--surfaces--dark--surface3: rgba(17, 20, 21, 1);
  --m3--surfaces--dark--surface4: rgba(17, 20, 21, 1);
  --m3--surfaces--dark--surface5: rgba(17, 20, 21, 1);
  --m3--state-layers--light--primary--opacity-008: rgba(182, 0, 116, 0.07999999821186066);
  --m3--state-layers--light--primary--opacity-012: rgba(182, 0, 116, 0.11999999731779099);
  --m3--state-layers--light--primary--opacity-016: rgba(182, 0, 116, 0.1599999964237213);
  --m3--state-layers--light--on-primary--opacity-008: rgba(255, 255, 255, 0.07999999821186066);
  --m3--state-layers--light--on-primary--opacity-012: rgba(255, 255, 255, 0.11999999731779099);
  --m3--state-layers--light--on-primary--opacity-016: rgba(255, 255, 255, 0.1599999964237213);
  --m3--state-layers--light--primary-container--opacity-008: rgba(255, 216, 230, 0.07999999821186066);
  --m3--state-layers--light--primary-container--opacity-012: rgba(255, 216, 230, 0.11999999731779099);
  --m3--state-layers--light--primary-container--opacity-016: rgba(255, 216, 230, 0.1599999964237213);
  --m3--state-layers--light--on-primary-container--opacity-008: rgba(61, 0, 36, 0.07999999821186066);
  --m3--state-layers--light--on-primary-container--opacity-012: rgba(61, 0, 36, 0.11999999731779099);
  --m3--state-layers--light--on-primary-container--opacity-016: rgba(61, 0, 36, 0.1599999964237213);
  --m3--state-layers--light--primary-fixed--opacity-008: rgba(255, 216, 230, 0.07999999821186066);
  --m3--state-layers--light--primary-fixed--opacity-012: rgba(255, 216, 230, 0.11999999731779099);
  --m3--state-layers--light--primary-fixed--opacity-016: rgba(255, 216, 230, 0.1599999964237213);
  --m3--state-layers--light--on-primary-fixed--opacity-008: rgba(61, 0, 36, 0.07999999821186066);
  --m3--state-layers--light--on-primary-fixed--opacity-012: rgba(61, 0, 36, 0.11999999731779099);
  --m3--state-layers--light--on-primary-fixed--opacity-016: rgba(61, 0, 36, 0.1599999964237213);
  --m3--state-layers--light--primary-fixed-dim--opacity-008: rgba(255, 175, 208, 0.07999999821186066);
  --m3--state-layers--light--primary-fixed-dim--opacity-012: rgba(255, 175, 208, 0.11999999731779099);
  --m3--state-layers--light--primary-fixed-dim--opacity-016: rgba(255, 175, 208, 0.1599999964237213);
  --m3--state-layers--light--on-primary-fixed-variant--opacity-008: rgba(140, 0, 88, 0.07999999821186066);
  --m3--state-layers--light--on-primary-fixed-variant--opacity-012: rgba(140, 0, 88, 0.11999999731779099);
  --m3--state-layers--light--on-primary-fixed-variant--opacity-016: rgba(140, 0, 88, 0.1599999964237213);
  --m3--state-layers--light--secondary--opacity-008: rgba(73, 88, 169, 0.07999999821186066);
  --m3--state-layers--light--secondary--opacity-012: rgba(73, 88, 169, 0.11999999731779099);
  --m3--state-layers--light--secondary--opacity-016: rgba(73, 88, 169, 0.1599999964237213);
  --m3--state-layers--light--on-secondary--opacity-008: rgba(255, 255, 255, 0.07999999821186066);
  --m3--state-layers--light--on-secondary--opacity-012: rgba(255, 255, 255, 0.11999999731779099);
  --m3--state-layers--light--on-secondary--opacity-016: rgba(255, 255, 255, 0.1599999964237213);
  --m3--state-layers--light--secondary-container--opacity-008: rgba(222, 224, 255, 0.07999999821186066);
  --m3--state-layers--light--secondary-container--opacity-012: rgba(222, 224, 255, 0.11999999731779099);
  --m3--state-layers--light--secondary-container--opacity-016: rgba(222, 224, 255, 0.1599999964237213);
  --m3--state-layers--light--on-secondary-container--opacity-008: rgba(0, 16, 90, 0.07999999821186066);
  --m3--state-layers--light--on-secondary-container--opacity-012: rgba(0, 16, 90, 0.11999999731779099);
  --m3--state-layers--light--on-secondary-container--opacity-016: rgba(0, 16, 90, 0.1599999964237213);
  --m3--state-layers--light--secondary-fixed--opacity-008: rgba(222, 224, 255, 0.07999999821186066);
  --m3--state-layers--light--secondary-fixed--opacity-012: rgba(222, 224, 255, 0.11999999731779099);
  --m3--state-layers--light--secondary-fixed--opacity-016: rgba(222, 224, 255, 0.1599999964237213);
  --m3--state-layers--light--on-secondary-fixed--opacity-008: rgba(0, 16, 90, 0.07999999821186066);
  --m3--state-layers--light--on-secondary-fixed--opacity-012: rgba(0, 16, 90, 0.11999999731779099);
  --m3--state-layers--light--on-secondary-fixed--opacity-016: rgba(0, 16, 90, 0.1599999964237213);
  --m3--state-layers--light--secondary-fixed-dim--opacity-008: rgba(186, 195, 255, 0.07999999821186066);
  --m3--state-layers--light--secondary-fixed-dim--opacity-012: rgba(186, 195, 255, 0.11999999731779099);
  --m3--state-layers--light--secondary-fixed-dim--opacity-016: rgba(186, 195, 255, 0.1599999964237213);
  --m3--state-layers--light--on-secondary-fixed-variant--opacity-008: rgba(48, 63, 144, 0.07999999821186066);
  --m3--state-layers--light--on-secondary-fixed-variant--opacity-012: rgba(48, 63, 144, 0.11999999731779099);
  --m3--state-layers--light--on-secondary-fixed-variant--opacity-016: rgba(48, 63, 144, 0.1599999964237213);
  --m3--state-layers--light--tertiary--opacity-008: rgba(0, 99, 154, 0.07999999821186066);
  --m3--state-layers--light--tertiary--opacity-012: rgba(0, 99, 154, 0.11999999731779099);
  --m3--state-layers--light--tertiary--opacity-016: rgba(0, 99, 154, 0.1599999964237213);
  --m3--state-layers--light--on-tertiary--opacity-008: rgba(255, 255, 255, 0.07999999821186066);
  --m3--state-layers--light--on-tertiary--opacity-012: rgba(255, 255, 255, 0.11999999731779099);
  --m3--state-layers--light--on-tertiary--opacity-016: rgba(255, 255, 255, 0.1599999964237213);
  --m3--state-layers--light--tertiary-container--opacity-008: rgba(206, 229, 255, 0.07999999821186066);
  --m3--state-layers--light--tertiary-container--opacity-012: rgba(206, 229, 255, 0.11999999731779099);
  --m3--state-layers--light--tertiary-container--opacity-016: rgba(206, 229, 255, 0.1599999964237213);
  --m3--state-layers--light--on-tertiary-container--opacity-008: rgba(0, 29, 50, 0.07999999821186066);
  --m3--state-layers--light--on-tertiary-container--opacity-012: rgba(0, 29, 50, 0.11999999731779099);
  --m3--state-layers--light--on-tertiary-container--opacity-016: rgba(0, 29, 50, 0.1599999964237213);
  --m3--state-layers--light--tertiary-fixed--opacity-008: rgba(206, 229, 255, 0.07999999821186066);
  --m3--state-layers--light--tertiary-fixed--opacity-012: rgba(206, 229, 255, 0.11999999731779099);
  --m3--state-layers--light--tertiary-fixed--opacity-016: rgba(206, 229, 255, 0.1599999964237213);
  --m3--state-layers--light--on-tertiary-fixed--opacity-008: rgba(0, 29, 50, 0.07999999821186066);
  --m3--state-layers--light--on-tertiary-fixed--opacity-012: rgba(0, 29, 50, 0.11999999731779099);
  --m3--state-layers--light--on-tertiary-fixed--opacity-016: rgba(0, 29, 50, 0.1599999964237213);
  --m3--state-layers--light--tertiary-fixed-dim--opacity-008: rgba(150, 204, 255, 0.07999999821186066);
  --m3--state-layers--light--tertiary-fixed-dim--opacity-012: rgba(150, 204, 255, 0.11999999731779099);
  --m3--state-layers--light--tertiary-fixed-dim--opacity-016: rgba(150, 204, 255, 0.1599999964237213);
  --m3--state-layers--light--on-tertiary-fixed-variant--opacity-008: rgba(0, 74, 118, 0.07999999821186066);
  --m3--state-layers--light--on-tertiary-fixed-variant--opacity-012: rgba(0, 74, 118, 0.11999999731779099);
  --m3--state-layers--light--on-tertiary-fixed-variant--opacity-016: rgba(0, 74, 118, 0.1599999964237213);
  --m3--state-layers--light--error--opacity-008: rgba(182, 36, 25, 0.07999999821186066);
  --m3--state-layers--light--error--opacity-012: rgba(182, 36, 25, 0.11999999731779099);
  --m3--state-layers--light--error--opacity-016: rgba(182, 36, 25, 0.1599999964237213);
  --m3--state-layers--light--on-error--opacity-008: rgba(255, 255, 255, 0.07999999821186066);
  --m3--state-layers--light--on-error--opacity-012: rgba(255, 255, 255, 0.11999999731779099);
  --m3--state-layers--light--on-error--opacity-016: rgba(255, 255, 255, 0.1599999964237213);
  --m3--state-layers--light--error-container--opacity-008: rgba(255, 218, 213, 0.07999999821186066);
  --m3--state-layers--light--error-container--opacity-012: rgba(255, 218, 213, 0.11999999731779099);
  --m3--state-layers--light--error-container--opacity-016: rgba(255, 218, 213, 0.1599999964237213);
  --m3--state-layers--light--on-error-container--opacity-008: rgba(65, 0, 0, 0.07999999821186066);
  --m3--state-layers--light--on-error-container--opacity-012: rgba(65, 0, 0, 0.11999999731779099);
  --m3--state-layers--light--on-error-container--opacity-016: rgba(65, 0, 0, 0.1599999964237213);
  --m3--state-layers--light--outline--opacity-008: rgba(115, 119, 127, 0.07999999821186066);
  --m3--state-layers--light--outline--opacity-012: rgba(115, 119, 127, 0.11999999731779099);
  --m3--state-layers--light--outline--opacity-016: rgba(115, 119, 127, 0.1599999964237213);
  --m3--state-layers--light--background--opacity-008: rgba(251, 252, 253, 0.07999999821186066);
  --m3--state-layers--light--background--opacity-012: rgba(251, 252, 253, 0.11999999731779099);
  --m3--state-layers--light--background--opacity-016: rgba(251, 252, 253, 0.1599999964237213);
  --m3--state-layers--light--on-background--opacity-008: rgba(25, 28, 29, 0.07999999821186066);
  --m3--state-layers--light--on-background--opacity-012: rgba(25, 28, 29, 0.11999999731779099);
  --m3--state-layers--light--on-background--opacity-016: rgba(25, 28, 29, 0.1599999964237213);
  --m3--state-layers--light--surface--opacity-008: rgba(248, 250, 250, 0.07999999821186066);
  --m3--state-layers--light--surface--opacity-012: rgba(248, 250, 250, 0.11999999731779099);
  --m3--state-layers--light--surface--opacity-016: rgba(248, 250, 250, 0.1599999964237213);
  --m3--state-layers--light--on-surface--opacity-008: rgba(25, 28, 29, 0.07999999821186066);
  --m3--state-layers--light--on-surface--opacity-012: rgba(25, 28, 29, 0.11999999731779099);
  --m3--state-layers--light--on-surface--opacity-016: rgba(25, 28, 29, 0.1599999964237213);
  --m3--state-layers--light--surface-variant--opacity-008: rgba(223, 226, 235, 0.07999999821186066);
  --m3--state-layers--light--surface-variant--opacity-012: rgba(223, 226, 235, 0.11999999731779099);
  --m3--state-layers--light--surface-variant--opacity-016: rgba(223, 226, 235, 0.1599999964237213);
  --m3--state-layers--light--on-surface-variant--opacity-008: rgba(67, 71, 78, 0.07999999821186066);
  --m3--state-layers--light--on-surface-variant--opacity-012: rgba(67, 71, 78, 0.11999999731779099);
  --m3--state-layers--light--on-surface-variant--opacity-016: rgba(67, 71, 78, 0.1599999964237213);
  --m3--state-layers--light--inverse-surface--opacity-008: rgba(46, 49, 50, 0.07999999821186066);
  --m3--state-layers--light--inverse-surface--opacity-012: rgba(46, 49, 50, 0.11999999731779099);
  --m3--state-layers--light--inverse-surface--opacity-016: rgba(46, 49, 50, 0.1599999964237213);
  --m3--state-layers--light--inverse-on-surface--opacity-008: rgba(239, 241, 242, 0.07999999821186066);
  --m3--state-layers--light--inverse-on-surface--opacity-012: rgba(239, 241, 242, 0.11999999731779099);
  --m3--state-layers--light--inverse-on-surface--opacity-016: rgba(239, 241, 242, 0.1599999964237213);
  --m3--state-layers--light--inverse-primary--opacity-008: rgba(255, 175, 208, 0.07999999821186066);
  --m3--state-layers--light--inverse-primary--opacity-012: rgba(255, 175, 208, 0.11999999731779099);
  --m3--state-layers--light--inverse-primary--opacity-016: rgba(255, 175, 208, 0.1599999964237213);
  --m3--state-layers--light--shadow--opacity-008: rgba(0, 0, 0, 0.07999999821186066);
  --m3--state-layers--light--shadow--opacity-012: rgba(0, 0, 0, 0.11999999731779099);
  --m3--state-layers--light--shadow--opacity-016: rgba(0, 0, 0, 0.1599999964237213);
  --m3--state-layers--light--surface-tint--opacity-008: rgba(182, 0, 116, 0.07999999821186066);
  --m3--state-layers--light--surface-tint--opacity-012: rgba(182, 0, 116, 0.11999999731779099);
  --m3--state-layers--light--surface-tint--opacity-016: rgba(182, 0, 116, 0.1599999964237213);
  --m3--state-layers--light--outline-variant--opacity-008: rgba(195, 198, 207, 0.07999999821186066);
  --m3--state-layers--light--outline-variant--opacity-012: rgba(195, 198, 207, 0.11999999731779099);
  --m3--state-layers--light--outline-variant--opacity-016: rgba(195, 198, 207, 0.1599999964237213);
  --m3--state-layers--light--scrim--opacity-008: rgba(0, 0, 0, 0.07999999821186066);
  --m3--state-layers--light--scrim--opacity-012: rgba(0, 0, 0, 0.11999999731779099);
  --m3--state-layers--light--scrim--opacity-016: rgba(0, 0, 0, 0.1599999964237213);
  --m3--state-layers--light--surface-container-highest--opacity-008: rgba(225, 227, 228, 0.07999999821186066);
  --m3--state-layers--light--surface-container-highest--opacity-012: rgba(225, 227, 228, 0.11999999731779099);
  --m3--state-layers--light--surface-container-highest--opacity-016: rgba(225, 227, 228, 0.1599999964237213);
  --m3--state-layers--light--surface-container-high--opacity-008: rgba(230, 232, 233, 0.07999999821186066);
  --m3--state-layers--light--surface-container-high--opacity-012: rgba(230, 232, 233, 0.11999999731779099);
  --m3--state-layers--light--surface-container-high--opacity-016: rgba(230, 232, 233, 0.1599999964237213);
  --m3--state-layers--light--surface-container--opacity-008: rgba(236, 238, 239, 0.07999999821186066);
  --m3--state-layers--light--surface-container--opacity-012: rgba(236, 238, 239, 0.11999999731779099);
  --m3--state-layers--light--surface-container--opacity-016: rgba(236, 238, 239, 0.1599999964237213);
  --m3--state-layers--light--surface-container-low--opacity-008: rgba(242, 244, 245, 0.07999999821186066);
  --m3--state-layers--light--surface-container-low--opacity-012: rgba(242, 244, 245, 0.11999999731779099);
  --m3--state-layers--light--surface-container-low--opacity-016: rgba(242, 244, 245, 0.1599999964237213);
  --m3--state-layers--light--surface-container-lowest--opacity-008: rgba(255, 255, 255, 0.07999999821186066);
  --m3--state-layers--light--surface-container-lowest--opacity-012: rgba(255, 255, 255, 0.11999999731779099);
  --m3--state-layers--light--surface-container-lowest--opacity-016: rgba(255, 255, 255, 0.1599999964237213);
  --m3--state-layers--light--surface-bright--opacity-008: rgba(248, 250, 250, 0.07999999821186066);
  --m3--state-layers--light--surface-bright--opacity-012: rgba(248, 250, 250, 0.11999999731779099);
  --m3--state-layers--light--surface-bright--opacity-016: rgba(248, 250, 250, 0.1599999964237213);
  --m3--state-layers--light--surface-dim--opacity-008: rgba(216, 218, 219, 0.07999999821186066);
  --m3--state-layers--light--surface-dim--opacity-012: rgba(216, 218, 219, 0.11999999731779099);
  --m3--state-layers--light--surface-dim--opacity-016: rgba(216, 218, 219, 0.1599999964237213);
  --m3--state-layers--dark--primary--opacity-008: rgba(255, 175, 208, 0.07999999821186066);
  --m3--state-layers--dark--primary--opacity-012: rgba(255, 175, 208, 0.11999999731779099);
  --m3--state-layers--dark--primary--opacity-016: rgba(255, 175, 208, 0.1599999964237213);
  --m3--state-layers--dark--on-primary--opacity-008: rgba(99, 0, 61, 0.07999999821186066);
  --m3--state-layers--dark--on-primary--opacity-012: rgba(99, 0, 61, 0.11999999731779099);
  --m3--state-layers--dark--on-primary--opacity-016: rgba(99, 0, 61, 0.1599999964237213);
  --m3--state-layers--dark--primary-container--opacity-008: rgba(140, 0, 88, 0.07999999821186066);
  --m3--state-layers--dark--primary-container--opacity-012: rgba(140, 0, 88, 0.11999999731779099);
  --m3--state-layers--dark--primary-container--opacity-016: rgba(140, 0, 88, 0.1599999964237213);
  --m3--state-layers--dark--on-primary-container--opacity-008: rgba(255, 216, 230, 0.07999999821186066);
  --m3--state-layers--dark--on-primary-container--opacity-012: rgba(255, 216, 230, 0.11999999731779099);
  --m3--state-layers--dark--on-primary-container--opacity-016: rgba(255, 216, 230, 0.1599999964237213);
  --m3--state-layers--dark--primary-fixed--opacity-008: rgba(255, 216, 230, 0.07999999821186066);
  --m3--state-layers--dark--primary-fixed--opacity-012: rgba(255, 216, 230, 0.11999999731779099);
  --m3--state-layers--dark--primary-fixed--opacity-016: rgba(255, 216, 230, 0.1599999964237213);
  --m3--state-layers--dark--on-primary-fixed--opacity-008: rgba(61, 0, 36, 0.07999999821186066);
  --m3--state-layers--dark--on-primary-fixed--opacity-012: rgba(61, 0, 36, 0.11999999731779099);
  --m3--state-layers--dark--on-primary-fixed--opacity-016: rgba(61, 0, 36, 0.1599999964237213);
  --m3--state-layers--dark--primary-fixed-dim--opacity-008: rgba(255, 175, 208, 0.07999999821186066);
  --m3--state-layers--dark--primary-fixed-dim--opacity-012: rgba(255, 175, 208, 0.11999999731779099);
  --m3--state-layers--dark--primary-fixed-dim--opacity-016: rgba(255, 175, 208, 0.1599999964237213);
  --m3--state-layers--dark--on-primary-fixed-variant--opacity-008: rgba(140, 0, 88, 0.07999999821186066);
  --m3--state-layers--dark--on-primary-fixed-variant--opacity-012: rgba(140, 0, 88, 0.11999999731779099);
  --m3--state-layers--dark--on-primary-fixed-variant--opacity-016: rgba(140, 0, 88, 0.1599999964237213);
  --m3--state-layers--dark--secondary--opacity-008: rgba(186, 195, 255, 0.07999999821186066);
  --m3--state-layers--dark--secondary--opacity-012: rgba(186, 195, 255, 0.11999999731779099);
  --m3--state-layers--dark--secondary--opacity-016: rgba(186, 195, 255, 0.1599999964237213);
  --m3--state-layers--dark--on-secondary--opacity-008: rgba(22, 39, 120, 0.07999999821186066);
  --m3--state-layers--dark--on-secondary--opacity-012: rgba(22, 39, 120, 0.11999999731779099);
  --m3--state-layers--dark--on-secondary--opacity-016: rgba(22, 39, 120, 0.1599999964237213);
  --m3--state-layers--dark--secondary-container--opacity-008: rgba(48, 63, 144, 0.07999999821186066);
  --m3--state-layers--dark--secondary-container--opacity-012: rgba(48, 63, 144, 0.11999999731779099);
  --m3--state-layers--dark--secondary-container--opacity-016: rgba(48, 63, 144, 0.1599999964237213);
  --m3--state-layers--dark--on-secondary-container--opacity-008: rgba(222, 224, 255, 0.07999999821186066);
  --m3--state-layers--dark--on-secondary-container--opacity-012: rgba(222, 224, 255, 0.11999999731779099);
  --m3--state-layers--dark--on-secondary-container--opacity-016: rgba(222, 224, 255, 0.1599999964237213);
  --m3--state-layers--dark--secondary-fixed--opacity-008: rgba(222, 224, 255, 0.07999999821186066);
  --m3--state-layers--dark--secondary-fixed--opacity-012: rgba(222, 224, 255, 0.11999999731779099);
  --m3--state-layers--dark--secondary-fixed--opacity-016: rgba(222, 224, 255, 0.1599999964237213);
  --m3--state-layers--dark--on-secondary-fixed--opacity-008: rgba(0, 16, 90, 0.07999999821186066);
  --m3--state-layers--dark--on-secondary-fixed--opacity-012: rgba(0, 16, 90, 0.11999999731779099);
  --m3--state-layers--dark--on-secondary-fixed--opacity-016: rgba(0, 16, 90, 0.1599999964237213);
  --m3--state-layers--dark--secondary-fixed-dim--opacity-008: rgba(186, 195, 255, 0.07999999821186066);
  --m3--state-layers--dark--secondary-fixed-dim--opacity-012: rgba(186, 195, 255, 0.11999999731779099);
  --m3--state-layers--dark--secondary-fixed-dim--opacity-016: rgba(186, 195, 255, 0.1599999964237213);
  --m3--state-layers--dark--on-secondary-fixed-variant--opacity-008: rgba(48, 63, 144, 0.07999999821186066);
  --m3--state-layers--dark--on-secondary-fixed-variant--opacity-012: rgba(48, 63, 144, 0.11999999731779099);
  --m3--state-layers--dark--on-secondary-fixed-variant--opacity-016: rgba(48, 63, 144, 0.1599999964237213);
  --m3--state-layers--dark--tertiary--opacity-008: rgba(150, 204, 255, 0.07999999821186066);
  --m3--state-layers--dark--tertiary--opacity-012: rgba(150, 204, 255, 0.11999999731779099);
  --m3--state-layers--dark--tertiary--opacity-016: rgba(150, 204, 255, 0.1599999964237213);
  --m3--state-layers--dark--on-tertiary--opacity-008: rgba(0, 51, 83, 0.07999999821186066);
  --m3--state-layers--dark--on-tertiary--opacity-012: rgba(0, 51, 83, 0.11999999731779099);
  --m3--state-layers--dark--on-tertiary--opacity-016: rgba(0, 51, 83, 0.1599999964237213);
  --m3--state-layers--dark--tertiary-container--opacity-008: rgba(0, 74, 118, 0.07999999821186066);
  --m3--state-layers--dark--tertiary-container--opacity-012: rgba(0, 74, 118, 0.11999999731779099);
  --m3--state-layers--dark--tertiary-container--opacity-016: rgba(0, 74, 118, 0.1599999964237213);
  --m3--state-layers--dark--on-tertiary-container--opacity-008: rgba(206, 229, 255, 0.07999999821186066);
  --m3--state-layers--dark--on-tertiary-container--opacity-012: rgba(206, 229, 255, 0.11999999731779099);
  --m3--state-layers--dark--on-tertiary-container--opacity-016: rgba(206, 229, 255, 0.1599999964237213);
  --m3--state-layers--dark--tertiary-fixed--opacity-008: rgba(206, 229, 255, 0.07999999821186066);
  --m3--state-layers--dark--tertiary-fixed--opacity-012: rgba(206, 229, 255, 0.11999999731779099);
  --m3--state-layers--dark--tertiary-fixed--opacity-016: rgba(206, 229, 255, 0.1599999964237213);
  --m3--state-layers--dark--on-tertiary-fixed--opacity-008: rgba(0, 29, 50, 0.07999999821186066);
  --m3--state-layers--dark--on-tertiary-fixed--opacity-012: rgba(0, 29, 50, 0.11999999731779099);
  --m3--state-layers--dark--on-tertiary-fixed--opacity-016: rgba(0, 29, 50, 0.1599999964237213);
  --m3--state-layers--dark--tertiary-fixed-dim--opacity-008: rgba(150, 204, 255, 0.07999999821186066);
  --m3--state-layers--dark--tertiary-fixed-dim--opacity-012: rgba(150, 204, 255, 0.11999999731779099);
  --m3--state-layers--dark--tertiary-fixed-dim--opacity-016: rgba(150, 204, 255, 0.1599999964237213);
  --m3--state-layers--dark--on-tertiary-fixed-variant--opacity-008: rgba(0, 74, 118, 0.07999999821186066);
  --m3--state-layers--dark--on-tertiary-fixed-variant--opacity-012: rgba(0, 74, 118, 0.11999999731779099);
  --m3--state-layers--dark--on-tertiary-fixed-variant--opacity-016: rgba(0, 74, 118, 0.1599999964237213);
  --m3--state-layers--dark--error--opacity-008: rgba(255, 180, 168, 0.07999999821186066);
  --m3--state-layers--dark--error--opacity-012: rgba(255, 180, 168, 0.11999999731779099);
  --m3--state-layers--dark--error--opacity-016: rgba(255, 180, 168, 0.1599999964237213);
  --m3--state-layers--dark--on-error--opacity-008: rgba(105, 0, 1, 0.07999999821186066);
  --m3--state-layers--dark--on-error--opacity-012: rgba(105, 0, 1, 0.11999999731779099);
  --m3--state-layers--dark--on-error--opacity-016: rgba(105, 0, 1, 0.1599999964237213);
  --m3--state-layers--dark--error-container--opacity-008: rgba(147, 3, 3, 0.07999999821186066);
  --m3--state-layers--dark--error-container--opacity-012: rgba(147, 3, 3, 0.11999999731779099);
  --m3--state-layers--dark--error-container--opacity-016: rgba(147, 3, 3, 0.1599999964237213);
  --m3--state-layers--dark--on-error-container--opacity-008: rgba(255, 218, 213, 0.07999999821186066);
  --m3--state-layers--dark--on-error-container--opacity-012: rgba(255, 218, 213, 0.11999999731779099);
  --m3--state-layers--dark--on-error-container--opacity-016: rgba(255, 218, 213, 0.1599999964237213);
  --m3--state-layers--dark--outline--opacity-008: rgba(141, 145, 153, 0.07999999821186066);
  --m3--state-layers--dark--outline--opacity-012: rgba(141, 145, 153, 0.11999999731779099);
  --m3--state-layers--dark--outline--opacity-016: rgba(141, 145, 153, 0.1599999964237213);
  --m3--state-layers--dark--background--opacity-008: rgba(25, 28, 29, 0.07999999821186066);
  --m3--state-layers--dark--background--opacity-012: rgba(25, 28, 29, 0.11999999731779099);
  --m3--state-layers--dark--background--opacity-016: rgba(25, 28, 29, 0.1599999964237213);
  --m3--state-layers--dark--on-background--opacity-008: rgba(225, 227, 228, 0.07999999821186066);
  --m3--state-layers--dark--on-background--opacity-012: rgba(225, 227, 228, 0.11999999731779099);
  --m3--state-layers--dark--on-background--opacity-016: rgba(225, 227, 228, 0.1599999964237213);
  --m3--state-layers--dark--surface--opacity-008: rgba(17, 20, 21, 0.07999999821186066);
  --m3--state-layers--dark--surface--opacity-012: rgba(17, 20, 21, 0.11999999731779099);
  --m3--state-layers--dark--surface--opacity-016: rgba(17, 20, 21, 0.1599999964237213);
  --m3--state-layers--dark--on-surface--opacity-008: rgba(196, 199, 200, 0.07999999821186066);
  --m3--state-layers--dark--on-surface--opacity-012: rgba(196, 199, 200, 0.11999999731779099);
  --m3--state-layers--dark--on-surface--opacity-016: rgba(196, 199, 200, 0.1599999964237213);
  --m3--state-layers--dark--surface-variant--opacity-008: rgba(67, 71, 78, 0.07999999821186066);
  --m3--state-layers--dark--surface-variant--opacity-012: rgba(67, 71, 78, 0.11999999731779099);
  --m3--state-layers--dark--surface-variant--opacity-016: rgba(67, 71, 78, 0.1599999964237213);
  --m3--state-layers--dark--on-surface-variant--opacity-008: rgba(195, 198, 207, 0.07999999821186066);
  --m3--state-layers--dark--on-surface-variant--opacity-012: rgba(195, 198, 207, 0.11999999731779099);
  --m3--state-layers--dark--on-surface-variant--opacity-016: rgba(195, 198, 207, 0.1599999964237213);
  --m3--state-layers--dark--inverse-surface--opacity-008: rgba(225, 227, 228, 0.07999999821186066);
  --m3--state-layers--dark--inverse-surface--opacity-012: rgba(225, 227, 228, 0.11999999731779099);
  --m3--state-layers--dark--inverse-surface--opacity-016: rgba(225, 227, 228, 0.1599999964237213);
  --m3--state-layers--dark--inverse-on-surface--opacity-008: rgba(25, 28, 29, 0.07999999821186066);
  --m3--state-layers--dark--inverse-on-surface--opacity-012: rgba(25, 28, 29, 0.11999999731779099);
  --m3--state-layers--dark--inverse-on-surface--opacity-016: rgba(25, 28, 29, 0.1599999964237213);
  --m3--state-layers--dark--inverse-primary--opacity-008: rgba(182, 0, 116, 0.07999999821186066);
  --m3--state-layers--dark--inverse-primary--opacity-012: rgba(182, 0, 116, 0.11999999731779099);
  --m3--state-layers--dark--inverse-primary--opacity-016: rgba(182, 0, 116, 0.1599999964237213);
  --m3--state-layers--dark--shadow--opacity-008: rgba(0, 0, 0, 0.07999999821186066);
  --m3--state-layers--dark--shadow--opacity-012: rgba(0, 0, 0, 0.11999999731779099);
  --m3--state-layers--dark--shadow--opacity-016: rgba(0, 0, 0, 0.1599999964237213);
  --m3--state-layers--dark--surface-tint--opacity-008: rgba(255, 175, 208, 0.07999999821186066);
  --m3--state-layers--dark--surface-tint--opacity-012: rgba(255, 175, 208, 0.11999999731779099);
  --m3--state-layers--dark--surface-tint--opacity-016: rgba(255, 175, 208, 0.1599999964237213);
  --m3--state-layers--dark--outline-variant--opacity-008: rgba(67, 71, 78, 0.07999999821186066);
  --m3--state-layers--dark--outline-variant--opacity-012: rgba(67, 71, 78, 0.11999999731779099);
  --m3--state-layers--dark--outline-variant--opacity-016: rgba(67, 71, 78, 0.1599999964237213);
  --m3--state-layers--dark--scrim--opacity-008: rgba(0, 0, 0, 0.07999999821186066);
  --m3--state-layers--dark--scrim--opacity-012: rgba(0, 0, 0, 0.11999999731779099);
  --m3--state-layers--dark--scrim--opacity-016: rgba(0, 0, 0, 0.1599999964237213);
  --m3--state-layers--dark--surface-container-highest--opacity-008: rgba(50, 53, 54, 0.07999999821186066);
  --m3--state-layers--dark--surface-container-highest--opacity-012: rgba(50, 53, 54, 0.11999999731779099);
  --m3--state-layers--dark--surface-container-highest--opacity-016: rgba(50, 53, 54, 0.1599999964237213);
  --m3--state-layers--dark--surface-container-high--opacity-008: rgba(39, 42, 43, 0.07999999821186066);
  --m3--state-layers--dark--surface-container-high--opacity-012: rgba(39, 42, 43, 0.11999999731779099);
  --m3--state-layers--dark--surface-container-high--opacity-016: rgba(39, 42, 43, 0.1599999964237213);
  --m3--state-layers--dark--surface-container--opacity-008: rgba(29, 32, 33, 0.07999999821186066);
  --m3--state-layers--dark--surface-container--opacity-012: rgba(29, 32, 33, 0.11999999731779099);
  --m3--state-layers--dark--surface-container--opacity-016: rgba(29, 32, 33, 0.1599999964237213);
  --m3--state-layers--dark--surface-container-low--opacity-008: rgba(25, 28, 29, 0.07999999821186066);
  --m3--state-layers--dark--surface-container-low--opacity-012: rgba(25, 28, 29, 0.11999999731779099);
  --m3--state-layers--dark--surface-container-low--opacity-016: rgba(25, 28, 29, 0.1599999964237213);
  --m3--state-layers--dark--surface-container-lowest--opacity-008: rgba(11, 15, 16, 0.07999999821186066);
  --m3--state-layers--dark--surface-container-lowest--opacity-012: rgba(11, 15, 16, 0.11999999731779099);
  --m3--state-layers--dark--surface-container-lowest--opacity-016: rgba(11, 15, 16, 0.1599999964237213);
  --m3--state-layers--dark--surface-bright--opacity-008: rgba(54, 58, 59, 0.07999999821186066);
  --m3--state-layers--dark--surface-bright--opacity-012: rgba(54, 58, 59, 0.11999999731779099);
  --m3--state-layers--dark--surface-bright--opacity-016: rgba(54, 58, 59, 0.1599999964237213);
  --m3--state-layers--dark--surface-dim--opacity-008: rgba(17, 20, 21, 0.07999999821186066);
  --m3--state-layers--dark--surface-dim--opacity-012: rgba(17, 20, 21, 0.11999999731779099);
  --m3--state-layers--dark--surface-dim--opacity-016: rgba(17, 20, 21, 0.1599999964237213);
  --m3--extended---teal--seed: rgba(0, 106, 104, 1);
  --m3--extended---teal--value: rgba(0, 106, 104, 1);
  --m3--extended---teal--light--teal: rgba(0, 106, 104, 1);
  --m3--extended---teal--light--on-teal: rgba(255, 255, 255, 1);
  --m3--extended---teal--light--teal-container: rgba(111, 247, 243, 1);
  --m3--extended---teal--light--on-teal-container: rgba(0, 32, 31, 1);
  --m3--extended---teal--light--color: rgba(0, 106, 104, 1);
  --m3--extended---teal--light--on-color: rgba(255, 255, 255, 1);
  --m3--extended---teal--light--color-container: rgba(111, 247, 243, 1);
  --m3--extended---teal--light--on-color-container: rgba(0, 32, 31, 1);
  --m3--extended---teal--dark--teal: rgba(77, 218, 214, 1);
  --m3--extended---teal--dark--on-teal: rgba(0, 55, 54, 1);
  --m3--extended---teal--dark--teal-container: rgba(0, 80, 78, 1);
  --m3--extended---teal--dark--on-teal-container: rgba(111, 247, 243, 1);
  --m3--extended---teal--dark--color: rgba(77, 218, 214, 1);
  --m3--extended---teal--dark--on-color: rgba(0, 55, 54, 1);
  --m3--extended---teal--dark--color-container: rgba(0, 80, 78, 1);
  --m3--extended---teal--dark--on-color-container: rgba(111, 247, 243, 1);
  --m3--extended---teal--group--tone0: rgba(0, 0, 0, 1);
  --m3--extended---teal--group--tone5: rgba(0, 20, 20, 1);
  --m3--extended---teal--group--tone10: rgba(0, 32, 31, 1);
  --m3--extended---teal--group--tone15: rgba(0, 43, 42, 1);
  --m3--extended---teal--group--tone20: rgba(0, 55, 54, 1);
  --m3--extended---teal--group--tone25: rgba(0, 67, 66, 1);
  --m3--extended---teal--group--tone30: rgba(0, 80, 78, 1);
  --m3--extended---teal--group--tone35: rgba(0, 93, 91, 1);
  --m3--extended---teal--group--tone40: rgba(0, 106, 104, 1);
  --m3--extended---teal--group--tone50: rgba(0, 133, 131, 1);
  --m3--extended---teal--group--tone60: rgba(0, 161, 159, 1);
  --m3--extended---teal--group--tone70: rgba(32, 190, 187, 1);
  --m3--extended---teal--group--tone80: rgba(77, 218, 214, 1);
  --m3--extended---teal--group--tone90: rgba(111, 247, 243, 1);
  --m3--extended---teal--group--tone95: rgba(175, 255, 252, 1);
  --m3--extended---teal--group--tone98: rgba(227, 255, 253, 1);
  --m3--extended---teal--group--tone99: rgba(242, 255, 253, 1);
  --m3--extended---teal--group--tone100: rgba(255, 255, 255, 1);
  --m3--extended---great--seed: rgba(56, 106, 32, 1);
  --m3--extended---great--value: rgba(56, 106, 32, 1);
  --m3--extended---great--light--great: rgba(56, 106, 31, 1);
  --m3--extended---great--light--on-great: rgba(255, 255, 255, 1);
  --m3--extended---great--light--great-container: rgba(184, 243, 151, 1);
  --m3--extended---great--light--on-great-container: rgba(7, 33, 0, 1);
  --m3--extended---great--dark--great: rgba(156, 214, 126, 1);
  --m3--extended---great--dark--on-great: rgba(17, 56, 0, 1);
  --m3--extended---great--dark--great-container: rgba(32, 81, 7, 1);
  --m3--extended---great--dark--on-great-container: rgba(184, 243, 151, 1);
  --m3--extended---great--group--tone0: rgba(0, 0, 0, 1);
  --m3--extended---great--group--tone5: rgba(3, 21, 0, 1);
  --m3--extended---great--group--tone10: rgba(7, 33, 0, 1);
  --m3--extended---great--group--tone15: rgba(11, 44, 0, 1);
  --m3--extended---great--group--tone20: rgba(17, 56, 0, 1);
  --m3--extended---great--group--tone25: rgba(22, 69, 0, 1);
  --m3--extended---great--group--tone30: rgba(32, 81, 7, 1);
  --m3--extended---great--group--tone35: rgba(44, 94, 20, 1);
  --m3--extended---great--group--tone40: rgba(56, 106, 31, 1);
  --m3--extended---great--group--tone50: rgba(80, 132, 54, 1);
  --m3--extended---great--group--tone60: rgba(105, 158, 77, 1);
  --m3--extended---great--group--tone70: rgba(130, 186, 101, 1);
  --m3--extended---great--group--tone80: rgba(156, 214, 126, 1);
  --m3--extended---great--group--tone90: rgba(184, 243, 151, 1);
  --m3--extended---great--group--tone95: rgba(205, 255, 176, 1);
  --m3--extended---great--group--tone98: rgba(238, 255, 222, 1);
  --m3--extended---great--group--tone99: rgba(247, 255, 236, 1);
  --m3--extended---great--group--tone100: rgba(255, 255, 255, 1);
  --m3--extended---neutral--seed: rgba(64, 73, 104, 1);
  --m3--extended---neutral--value: rgba(64, 73, 104, 1);
  --m3--extended---neutral--light--neutral: rgba(63, 90, 169, 1);
  --m3--extended---neutral--light--on-neutral: rgba(255, 255, 255, 1);
  --m3--extended---neutral--light--neutral-container: rgba(219, 225, 255, 1);
  --m3--extended---neutral--light--on-neutral-container: rgba(0, 23, 76, 1);
  --m3--extended---neutral--light--color: rgba(63, 90, 169, 1);
  --m3--extended---neutral--light--on-color: rgba(255, 255, 255, 1);
  --m3--extended---neutral--light--color-container: rgba(219, 225, 255, 1);
  --m3--extended---neutral--light--on-color-container: rgba(0, 23, 76, 1);
  --m3--extended---neutral--dark--neutral: rgba(180, 196, 255, 1);
  --m3--extended---neutral--dark--on-neutral: rgba(2, 41, 120, 1);
  --m3--extended---neutral--dark--neutral-container: rgba(37, 66, 144, 1);
  --m3--extended---neutral--dark--on-neutral-container: rgba(219, 225, 255, 1);
  --m3--extended---neutral--dark--color: rgba(180, 196, 255, 1);
  --m3--extended---neutral--dark--on-color: rgba(2, 41, 120, 1);
  --m3--extended---neutral--dark--color-container: rgba(37, 66, 144, 1);
  --m3--extended---neutral--dark--on-color-container: rgba(219, 225, 255, 1);
  --m3--extended---neutral--group--tone0: rgba(0, 0, 0, 1);
  --m3--extended---neutral--group--tone5: rgba(0, 13, 53, 1);
  --m3--extended---neutral--group--tone10: rgba(0, 23, 76, 1);
  --m3--extended---neutral--group--tone15: rgba(0, 32, 98, 1);
  --m3--extended---neutral--group--tone20: rgba(2, 41, 120, 1);
  --m3--extended---neutral--group--tone25: rgba(22, 54, 132, 1);
  --m3--extended---neutral--group--tone30: rgba(37, 66, 144, 1);
  --m3--extended---neutral--group--tone35: rgba(50, 78, 156, 1);
  --m3--extended---neutral--group--tone40: rgba(63, 90, 169, 1);
  --m3--extended---neutral--group--tone50: rgba(89, 115, 196, 1);
  --m3--extended---neutral--group--tone60: rgba(115, 141, 224, 1);
  --m3--extended---neutral--group--tone70: rgba(142, 168, 253, 1);
  --m3--extended---neutral--group--tone80: rgba(180, 196, 255, 1);
  --m3--extended---neutral--group--tone90: rgba(219, 225, 255, 1);
  --m3--extended---neutral--group--tone95: rgba(239, 240, 255, 1);
  --m3--extended---neutral--group--tone98: rgba(250, 248, 255, 1);
  --m3--extended---neutral--group--tone99: rgba(254, 251, 255, 1);
  --m3--extended---neutral--group--tone100: rgba(255, 255, 255, 1);

  /* Text-size styles */
  --m3--display--large: 57px;
  --m3--display--medium: 45px;
  --m3--display--small: 36px;
  --m3--headline--large: 32px;
  --m3--headline--medium: 28px;
  --m3--headline--small: 24px;
  --m3--title--large: 22px;
  --m3--title--medium: 16px;
  --m3--title--small: 14px;
  --m3--body--large: 16px;
  --m3--body--medium: 14px;
  --m3--body--small: 12px;
  --m3--label--large---prominent: 14px;
  --m3--label--large: 14px;
  --m3--label--medium---prominent: 12px;
  --m3--label--medium: 12px;
  --m3--label--small: 11px;

  /* Effect styles */
  --m3---elevation--light--1: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
  --m3---elevation--light--2: 0px 2px 6px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
  --m3---elevation--light--3: 0px 1px 3px rgba(0, 0, 0, 0.3), 0px 4px 8px rgba(0, 0, 0, 0.15);
  --m3---elevation--light--4: 0px 2px 3px rgba(0, 0, 0, 0.3), 0px 6px 10px rgba(0, 0, 0, 0.15);
  --m3---elevation--light--5: 0px 4px 4px rgba(0, 0, 0, 0.3), 0px 8px 12px rgba(0, 0, 0, 0.15);
  --m3---elevation--dark--1: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px rgba(0, 0, 0, 0.15);
  --m3---elevation--dark--2: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px rgba(0, 0, 0, 0.15);
  --m3---elevation--dark--3: 0px 1px 3px rgba(0, 0, 0, 0.3), 0px 4px 8px rgba(0, 0, 0, 0.15);
  --m3---elevation--dark--4: 0px 2px 3px rgba(0, 0, 0, 0.3), 0px 6px 10px rgba(0, 0, 0, 0.15);
  --m3---elevation--dark--5: 0px 4px 4px rgba(0, 0, 0, 0.3), 0px 8px 12px rgba(0, 0, 0, 0.15);
}
