.root {
  position: relative;
  padding: 1rem;

  .img {
    position: relative;
    width: 100%;
    max-width: 234px;
    border-radius: 10px;
    margin: 1rem auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .header,
  .sentence {
    font-family: 'PingFang HK';
    font-style: normal;
  }

  .header {
    font-weight: 500;
    font-size: 1.7rem;
    color: #554d89;
    margin-bottom: 0.5rem;
  }

  .sentence {
    color: #716c80;
    font-size: 1.3rem;
    font-weight: 400;
  }

  .btnContainer {
    display: flex;
    margin-top: 2rem;
    width: 100%;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
    .link {
      text-decoration: none;
      flex: 1;
    }
    .btn {
      border-radius: 26px;
    }
  }

  .optInBtnContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
