.root {
  position: fixed;
  right: -25.5rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 0;
  background: rgba(22, 25, 29, 0.7);
  border-radius: 1.25rem;
  transition: right 100ms ease-in, opacity 100ms ease-in, width 1000ms;
  opacity: 0;
}

.root .paper {
  height: 100%;
  width: 100%;
  background: #ffffff;
  border-radius: 0px;
  border-radius: 1.25rem;
}

.root .paper .header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  position: relative;
}

.root .paper .header .icon {
  margin-left: 0.5rem;
}

.root .paper .header .closeIconContainer {
  position: absolute;
  right: 1rem;
  color: #667085;
  cursor: pointer;
}

.content {
  position: relative;
}

.layoutBlur {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 0;
}

.layoutBlur::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(136deg, rgba(234, 255, 254, 0.90) 0%, rgba(205, 201, 241, 0.90) 100%);
  filter: blur(10px);
}

.layoutBlur .padlock {
  display: flex;
  width: 72px;
  height: 72px;
  position: absolute;
  top: 12px;
}

.layoutBlur h3 {
  color:  #4958A9;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  position: absolute;
  top: 66px;
  left: 0;
}

.open {
  right: 2rem;
  padding: 1.5rem;
  width: 25.5rem;
  opacity: 1;
  transition: right 200ms ease-out, opacity 200ms ease-out;
}
