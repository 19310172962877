.container{
  height:100%;
  display:flex;
  justify-content: center;
  align-items:center;
}

.avatar_icon {
  color: #0788F5;
}

.title{
  color: #554D89;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.01em;
  margin-bottom: 2rem;
  padding: 0px 50px;
}

.description{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  color: #716C80;
  padding: 0px 50px;
}
