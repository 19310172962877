.root {
  min-width: 100vw !important;
  height: 100vh;
  display: flex !important;
  flex-direction: column;
  flex: 1;
  position: relative;

  .videoContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }

  .container {
    height: 100%;
    .form {
      display: flex;
      gap: 16px;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }
  }

  .chatIconContainer {
    position: absolute;
    width: 2rem;
    height: 2rem;
    right: 1rem;
    bottom: 8rem;
    color: #fff;
    text-align: center;
    font-family: 'Inter';
    font-size: 0.8rem;
    cursor: pointer;
    .icon {
      width: 100%;
    }
  }
}
