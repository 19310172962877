@import "sanitize.css";

@import "./variables.css";
@import "./theme/index.css";

html,
body,
#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
