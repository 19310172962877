.root {
  height: 100%;
  position: relative;
  .controllers {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;
    gap: 8px;
    width: 44px;
    background: rgba(22, 25, 29, 0.7);
    border-radius: 16px;
    .icon {
      cursor: pointer;
    }
  }
  .iframeContainer {
    height: 100%;
  }
  .micContainer {
    position: fixed;
    top: 4rem;
    bottom: 0;
    right: 0;
    width: 45%;
    z-index: 1300;
    .mic {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 4rem;
      height: 4rem;
      padding-right: 1rem;
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      right: 1rem;
      bottom: 50%;
      cursor: pointer;
      z-index: 1300;
      transition: width 400ms;
    }
    .micOpened {
      width: 30vw;
      min-width: 300px;
      justify-content: flex-end;
    }
  }
}
.pulseContainer {
  display: flex;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 36px;
}
