.root:global(.MuiFab-primary) {
  background-color: var(--primary-color);
  &:hover {
    background-color: var(--primary-hover-color);
  }
}

.root:global(.MuiFab-secondary) {
  background-color: var(--light-blue);
  &:hover {
    background-color: var(--secondary-hover-color);
  }
}
