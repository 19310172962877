.root {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  .logoContainer,
  .textContainer,
  .actionsContainer {
    padding: 0 40px;
  }
  .logoContainer {
    height: 48px;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    img {
      height: 100%;
    }
  }
  .textContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 32px;
  }
  .actionsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* .txfEmail {
      :global(.MuiOutlinedInput-root).Mui-focused fieldset {
        border-color: red;
      }
    } */
    .btnLogin,
    .btnSocial {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      text-transform: capitalize;
    }
    .btnLogin {
      border-radius: 100px;
      background-color: var(--primary-color);
    }
    .btnSocial {
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      color: var(--font-secondary-color);
    }
  }
  .videoContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }

  .endCallContainer {
    position: absolute;
    top: 20px;
    color: white;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    .btnEndCall {
      z-index: 2;
    }
    .title {
      position: absolute;
      display: flex;
      justify-content: center;
      font-family: 'Inter';
      width: 100%;
    }
  }

  .container {
    height: 100%;
    .form {
      display: flex;
      gap: 16px;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }
  }

  .chatIconContainer {
    position: absolute;
    width: 2rem;
    height: 2rem;
    right: 1rem;
    bottom: 8rem;
    color: #fff;
    text-align: center;
    font-family: 'Inter';
    font-size: 0.8rem;
    cursor: pointer;
    .icon {
      width: 100%;
    }
  }
}

.dialog {
  .content {
    padding-top: 16px;
  }
  .title {
    display: flex;
    justify-content: center;
    div {
      display: flex;
      align-items: center;
      background: #fdf2fa;
      color: #c11574;
      border-radius: 16px;
      padding: 0 12px;
      gap: 4px;
    }
  }

  .btnClose {
    position: absolute;
    right: 8px;
    top: 8px;
    color: #9e9e9e;
  }
  :global .MuiDialog-container {
    align-items: flex-end;
    .MuiPaper-root {
      height: 50%;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
    }
  }
}

.dialogLarge {
  :global .MuiDialog-container {
    .MuiPaper-root {
      height: 70%;
    }
  }
}

.dialogWidth {
  width: 500px;
}
