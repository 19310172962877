:root {
  /* Color */
  --primary-color: #DD2590;
  --primary-50: rgba(253, 242, 250, 1);
  --primary-700: rgba(193, 21, 116, 1);
  --primary-hover-color: #D11B85;
  --secondary-color: #F8F9FC;
  --secondary-hover-color: #f9f9fa;
  --secondary-50: #F8F9FC;
  --secondary-700: #363F72;
  --terciary-color: #697586;
  --alternate-color: #0597E8;
  --pink-color: #FCCEEE;
  --tea-color: #99F6E0;
  --gray-color: #E3E8EF;
  --blue-color: #B9E6FE;
  --purple-color: #5925DC;
  --font-primary-color: #554D89;
  --font-secondary-color: #716C80;
  --cyan-200: #A5F0FC;
  --cyan-600: #088AB2;
  --cyan-700: #0E7090;
  --error-25: #FFFBFA;
  --error-50: #FEF3F2;
  --error-100: #FEE4E2;
  --error-300: #FDA29B;
  --error-600: #D92D20;
  --error-700: #B42318;
  --error-900: #7A271A;
  --gray-25: #FCFCFD;
  --gray-100: #F2F4F7;
  --gray-300: #D0D5DD;
  --gray-700: #344054;
  --gray-900: #111322;
  --green-25: #F6FEF9;
  --green-100: #D3F8DF;
  --green-300: #73E2A3;
  --green-600: #099250;
  --green-700: #087443;
  --green-900: #084C2E;
  --success-50: #ECFDF3;
  --success-700: #027A48;
  --teal-200: #99F6E0;
  --teal-600: #0E9384;
  --teal-700: #107569;
  --violet-200: #DDD6FE;
  --violet-600: #7839EE;
  --light-blue: #DEE0FF;
  --violet-700: #6927DA;
  --warning-50: #FFFAEB;
  --warning-700: #B54708;
  /* End color */
}
