.root {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 32px;
  gap: 8px;

  .pick {
    background-color: var(--purple-color);
    box-sizing: border-box;
    transition: height 300ms ease;
    width: 16px;
  }

  .pick:nth-child(1),
  .pick:nth-child(2),
  .pick:nth-child(7),
  .pick:nth-child(8) {
    animation: smallPick 500ms infinite;
  }

  .pick:nth-child(3),
  .pick:nth-child(6) {
    animation: mediumPick 400ms infinite;
  }

  .pick:nth-child(4),
  .pick:nth-child(5) {
    animation: largePick 450ms infinite;
  }

  @keyframes smallPick {
    from {
      height: 0;
    }

    to {
      border-top: 1px solid var(--primary-color);
      border-bottom: 1px solid var(--primary-color);
      height: 4px;
    }
  }

  @keyframes mediumPick {
    from {
      height: 0;
    }

    to {
      border-top: 3px solid var(--primary-color);
      border-bottom: 3px solid var(--primary-color);
      height: 8px;
    }
  }

  @keyframes largePick {
    from {
      height: 0;
    }

    to {
      border-top: 7px solid var(--primary-color);
      border-bottom: 7px solid var(--primary-color);
      height: 16px;
    }
  }
}
