.root {
  padding-bottom: 10rem;
  .btnAdd {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
  }
}

.dialogTitle {
    margin-bottom: 1rem !important;
}

.btnClose {
  position: absolute !important;
  right: 12px;
  top: 14px;
  color: #9e9e9e !important;
}
