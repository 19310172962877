.root {
  padding: 1rem 1rem 1rem 1rem;
  .sentence,
  .txfWord {
    font-family: "PingFang HK";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    color: #554d89;
    margin-bottom: 0;
  }
  .sentence {
    width: 328px;
    height: 112px;
    padding: 0px 24px 16px 24px;
    white-space: break-spaces;
    font-size: 23px;
    line-height: 32px;
    font-family: "Inter";
  }
  .txfWord {
    border: none;
    color: #0597E8;
    border-bottom: 1px solid #554d89;
    padding: 0;
    margin: 0;
    border-bottom: 3px solid;
    &:focus-visible {
      background-color: rgba(5, 151, 232, 0.2);
      border: none;
      outline: none;
    }
    &:-internal-autofill-selected {
      color: #554d89 !important;
    }
  }

  .txfWordFail {
    color: #f4483c !important;
  }

  .txfWordSuccess {
    color: #00de27 !important;
  }

  .actionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 3rem;
    width: 328px;
    height: 56px;
    .btnCheck {
      border-radius: 26px;
      svg {
        margin-left: 0.3rem;
      }
    }
  }
  .result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.7rem;
    width: 328px;
    height: 72px;
  }
  .value{
    width: 328px;
    height: 88px;
    display: flex;
    align-content: center;
    justify-content: center;

  }
  .error{
    color: red;
  }
}
