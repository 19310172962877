:root {
  --m3-sys-color-primary: var(--m3--sys--dark--primary);
  --m3-sys-color-on-primary: var(--m3--sys--dark--on-primary);
  --m3-sys-color-primary-container: var(--m3--sys--dark--primary-container);
  --m3-sys-color-on-primary-container: var(--m3--sys--dark--on-primary-container);
  --m3-sys-color-secondary: var(--m3--sys--dark--secondary);
  --m3-sys-color-on-secondary: var(--m3--sys--dark--on-secondary);
  --m3-sys-color-secondary-container: var(--m3--sys--dark--secondary-container);
  --m3-sys-color-on-secondary-container: var(--m3--sys--dark--on-secondary-container);
  --m3-sys-color-tertiary: var(--m3--sys--dark--tertiary);
  --m3-sys-color-on-tertiary: var(--m3--sys--dark--on-tertiary);
  --m3-sys-color-tertiary-container: var(--m3--sys--dark--tertiary-container);
  --m3-sys-color-on-tertiary-container: var(--m3--sys--dark--on-tertiary-container);
  --m3-sys-color-error: var(--m3--sys--dark--error);
  --m3-sys-color-error-container: var(--m3--sys--dark--error-container);
  --m3-sys-color-on-error: var(--m3--sys--dark--on-error);
  --m3-sys-color-on-error-container: var(--m3--sys--dark--on-error-container);
  --m3-sys-color-background: var(--m3--sys--dark--background);
  --m3-sys-color-on-background: var(--m3--sys--dark--on-background);
  --m3-sys-color-surface: var(--m3--sys--dark--surface);
  --m3-sys-color-on-surface: var(--m3--sys--dark--on-surface);
  --m3-sys-color-surface-variant: var(--m3--sys--dark--surface-variant);
  --m3-sys-color-on-surface-variant: var(--m3--sys--dark--on-surface-variant);
  --m3-sys-color-outline: var(--m3--sys--dark--outline);
  --m3-sys-color-inverse-on-surface: var(--m3--sys--dark--inverse-on-surface);
  --m3-sys-color-inverse-surface: var(--m3--sys--dark--inverse-surface);
  --m3-sys-color-inverse-primary: var(--m3--sys--dark--inverse-primary);
  --m3-sys-color-shadow: var(--m3--sys--dark--shadow);
  --m3-sys-color-surface-tint: var(--m3--sys--dark--surface-tint);
  --m3-sys-color-outline-variant: var(--m3--sys--dark--outline-variant);
  --m3-sys-color-scrim: var(--m3--sys--dark--scrim);
  --m3---elevation--1: var(--m3---elevation--dark--1);
  --m3---elevation--2: var(--m3---elevation--dark--2);
  --m3---elevation--3: var(--m3---elevation--dark--3);
  --m3---elevation--4: var(--m3---elevation--dark--4);
  --m3---elevation--5: var(--m3---elevation--dark--5);
}
