.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  gap: 30px;

  .score {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;

    .scoreBreakdown {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 28px;
    }
  }

  .timer {
    min-height: 78px;
  }

  .stopCircleIcon {
    cursor: pointer;
  }

  .reactive {
    padding: 0px 24px 16px;
  }

  .actions {
    width: 100%;
    min-height: 72px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .listeningWaveContainer {
    min-height: 32px;
    position: relative;
    bottom: 6px;
  }
}
