.root:global(.MuiTypography-root) {
  font-family: 'Rubik';
  color: var(--gray-700);
  font-weight: 500;
}

.root:global(.MuiTypography-h1),
.root:global(.MuiTypography-h2),
.root:global(.MuiTypography-h3) {
  letter-spacing: -0.02em;
}

.root:global(.MuiTypography-h1) {
  font-size: 3.75rem;
  line-height: 4.5rem;
}

.root:global(.MuiTypography-h2) {
  font-size: 3rem;
  line-height: 3.75rem;
}

.root:global(.MuiTypography-h3) {
  font-size: 2.25rem;
  line-height: 2.75rem;
}

.root:global(.MuiTypography-h5),
.root:global(.MuiTypography-subtitle1),
.root:global(.MuiTypography-subtitle2),
.root:global(.MuiTypography-body1),
.root:global(.MuiTypography-body2) {
  font-family: 'Inter';
}

.root:global(.MuiTypography-subtitle2),
.root:global(.MuiTypography-body1),
.root:global(.MuiTypography-body2) {
  color: var(--font-secondary-color);
}

.root:global(.MuiTypography-h5) {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
}

.root:global(.MuiTypography-subtitle1) {
  font-size: 1.875rem;
  line-height: 2.375rem;
  font-weight: 600;
}

.root:global(.MuiTypography-subtitle2) {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.root:global(.MuiTypography-body1) {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.root:global(.MuiTypography-body2) {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
}
