.root {
  .messagesContainer {
    height: 40vh;
    overflow: auto;
    padding-bottom: 0.5rem;
    .messageWrap {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem;
      padding: 0 1rem;
      .avatar {
        height: 3rem;
        margin-right: 0.5rem;
        border-radius: 50%;
      }
      .data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .userName {
          font-family: Inter;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.32px;
          color: #716c80;
          font-weight: 300;
        }
        .message {
          font-family: Inter;
          font-style: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.32px;
          color: #554d89;
          font-weight: 500;
        }
      }
    }
  }
  .write {
    position: relative;
    display: flex;
    align-items: center;

    .sendIcon {
      position: absolute;
      right: 1rem;
      font-size: 2rem;
    }
  }
}
