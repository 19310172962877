.root {
  height: 28px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px 4px 12px;
  gap: 8px;
  background-color: var(--gray-100);
  border-radius: 16px;
  box-shadow: var(--m3---elevation--1);
  .icon {
    font-size: 14px;
    line-height: initial;
  }
  .label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--gray-700);
  }
  &.primary {
    background-color: var(--primary-50);
    .label {
      color: var(--primary-700);
    }
  }
  &.error {
    background-color: var(--error-50);
    .label {
      color: var(--error-700);
    }
  }
  &.warning {
    background-color: var(--warning-50);
    .label {
      color: var(--warning-700);
    }
  }
  &.success {
    background-color: var(--success-50);
    .label {
      color: var(--success-700);
    }
  }
  &.secondary {
    background-color: var(--secondary-50);
    .label {
      color: var(--secondary-700);
    }
  }
}
