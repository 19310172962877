.root {
  .countdownWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 16px;
    .timeSection {
      min-width: 5rem;
    }
  }
}
